

.text-overlay {
  position: absolute; /* Make the text overlay positioned relative to the image */
  bottom: 0; /* Align the overlay to the bottom */
  /* width: 100%; Span the full width of the image */
  text-align: center; /* Center the text horizontally */
  color: white; /* Text color (adjust as desired) */
  background-color: rgba(0, 0, 0, 0.3); /*  Semi-transparent black background (optional) */
  padding: 10px; /* Padding around the text (adjust as desired) */
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}